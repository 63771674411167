export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function portfolioComparator(a, b) {
    if (a.create_date > b.create_date) {
        return -1
    }
    if (a.create_date < b.create_date) {
        return 1
    }
    return 0
}

export function dataURLToBlob(dataURL) {
    const [prefix, base64Data] = dataURL.split(',');
    const type = prefix.split(':')[1].split(';')[0];
    const binary = atob(base64Data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  }
